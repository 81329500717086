// eslint-disable-next-line vue/custom-event-name-casing
import { merge, uniqBy } from 'lodash'
import requestShowInitialState from './state'

export default {
  reinitializeState: (state) => {
    if (state.jobPollIntervalId) {
      clearInterval(state.jobPollIntervalId)
    }
    Object.assign(state, requestShowInitialState)
  },
  setRequest: (state, val) => {
    state.request = val
  },
  setDocuments: (state, val) => {
    state.documents = val
  },
  setRequestStaff: (state, val) => {
    const index = state.requestStaff.findIndex((staff) => +staff.id === +val.id)
    if (index !== -1) {
      state.requestStaff.splice(index, 1, val)
    } else {
      state.requestStaff = val
    }
  },
  setRequestTags: (state, val) => {
    state.requestTags = val
  },
  setRequestTasks: (state, val) => {
    state.requestTasks = val
  },
  setCurrentRequestTasksCount: (state, val) => {
    state.currentRequestTasksCount = val
  },
  setTotalRequestTasksCount: (state, val) => {
    state.totalRequestTasksCount = val
  },
  setTask: (state, val) => {
    const index = state.requestTasks.findIndex((task) => +task.id === +val.id)
    if (index !== -1) {
      state.requestTasks.splice(index, 1, val)
    } else {
      state.requestTasks = [...state.requestTasks, val]
    }
  },
  setTotalRequestTimelineCount: (state, val) => {
    state.totalRequestTimelineCount = val
  },
  setRequestTimeline: (state, val) => {
    state.requestTimeline = val
  },
  setSelectedTimelineEvent: (state, val) => {
    state.selectedTimelineEvent = val
  },
  setRequestInvoice: (state, val) => {
    const index = state.requestInvoices.findIndex(
      (invoice) => +invoice.id === +val.id
    )
    if (index !== -1) {
      state.requestInvoices.splice(index, 1, val)
    } else {
      state.requestInvoices = [...state.requestInvoices, val]
    }
  },
  setRequestInvoices: (state, val) => {
    state.requestInvoices = val
  },
  setRequestStaffHours: (state, val) => {
    state.requestStaffHours = val
  },
  setDocumentsFolders: (state, val) => {
    state.documentsFolders = val
  },
  setTotalDocumentsFoldersCount: (state, val) => {
    state.totalDocumentsFoldersCount = val
  },
  setDocumentsChildFolders: (state, val) => {
    state.documentsFolders = [...new Set(val)]
  },
  setSearchDocumentResultsCount: (state, val) => {
    state.searchDocumentResultsCount = val
  },
  setCurrentDocumentsCount: (state, val) => {
    state.currentDocumentsCount = val
  },
  setDocumentsStateTimestamp: (state, val) => {
    state.documentsStateTimestamp = val
  },
  setTotalDocumentsCount: (state, val) => {
    state.totalDocumentsCount = val
  },
  setDocumentFilters: (state, val) => {
    state.currentDocumentFilters = val
  },
  setInvoiceTemplates: (state, val) => {
    state.invoiceTemplates = val
  },
  setSelectedChangeDocVisibility: (state, val) => {
    state.selectedChangeDocVisibility = val
  },
  setPreviewRequestPage: (state, val) => {
    state.previewRequestPage = val
  },
  setMessageToRequester: (state, val) => {
    state.messageToRequester = val
  },
  setDocument: (state, val) => {
    const index = state.documents.findIndex((i) => i.id === val.id)
    if (index === -1) return
    Object.assign(state.documents[index], val)
  },
  setActiveModal: (state, val) => {
    state.activeModal = val
  },
  setDisplayInternalMsgBox: (state, val) => {
    state.displayInternalMsgBox = val
  },
  setDisplayCreateInvoiceMsgBox: (state, val) => {
    state.displayCreateInvoiceMsgBox = val
  },
  setDisplayExternalMsgBox: (state, val) => {
    state.displayExternalMsgBox = val
  },
  setDisplayCreateStaffTimeBox: (state, val) => {
    state.displayStaffTimeBox = val
  },
  setDisplayCreateTaskMsgBox: (state, val) => {
    state.displayCreateTaskMsgBox = val
  },
  setDisplayMsgBox: (state, val) => {
    state.displayMsgBox = val
  },
  setDisplayEditRequestTextBox: (state, val) => {
    state.displayEditRequestTextBox = val
  },
  setCurrentAction: (state, val) => {
    state.currentAction = val
  },
  setEditingTask: (state, val) => {
    state.editingTask = val
  },
  setDocumentsProcessingIds: (state, val) => {
    state.documentsProcessingIds = val
  },
  setRequestDocumentRisk: (state, val) => {
    state.requestDocumentRisk = val
  },
  setStatus(state, value) {
    state.request = Object.assign({}, state.request, {
      request_state: value
    })
  },
  setClosureTemplates(state, val) {
    state.closureTemplates = uniqBy(val, 'id')
  },
  setDataFetchState(state, val) {
    state.dataFetchState = merge({}, state.dataFetchState, val)
  },
  setDataOptions(state, opts) {
    Object.keys(opts).forEach((key) => {
      if (opts[key].length) {
        state.dataOptions[key] = uniqBy(
          [...state.dataOptions[key], ...opts[key]],
          'id'
        )
      } else {
        state.dataOptions[key] = []
      }
    })
  },
  setFilteredRequestLoading(state, val) {
    state.filteredRequestLoading = val
  },
  setPinnedNote(state, val) {
    state.pinnedNote = val
  },
  setJobPollIntervalId(state, val) {
    if (state.jobPollIntervalId && state.jobPollIntervalId !== val) {
      clearInterval(state.jobPollIntervalId)
    }
    state.jobPollIntervalId = val
  }
}
