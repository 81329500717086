import '../src/polyfills.js'
import { createApp } from 'vue'
import { createStore } from 'vuex'
import { abilitiesPlugin } from '@casl/vue'
import axios from 'axios'
import { injectToasted } from '../src/utils/vue-toasted/inject_toasted.js'
import VueCookies from 'vue3-cookies'
import Popper from 'vue3-popper'
import App from '../src/routes/App.vue'
import { router } from '../src/routes/router'
import { ability, abilityPlugin } from '../src/vue_shared/ability'
import { globalModule } from '../src/store/globalModule'
import { rapidReviewModule } from '../src/pages/rapid_review/store/rapidReviewModule'
import { adminModule } from '../src/pages/admin/store/module'
import { documentsModule } from '../src/pages/documents/store/store'
import { requestShowModule } from '../src/store/requestShowModule'
import { requestsModule } from '../src/store/requestsModule'
import currentUserPresent from '../src/mixins/currentUserPresent'
import removeNullFromObject from '../src/mixins/removeNullFromObject'
// Styles
import '../styles/globals.scss'

export const app = createApp({
  ...App
})

document.addEventListener('DOMContentLoaded', () => {
  const store = createStore({
    modules: {
      requests: requestsModule,
      documents: documentsModule,
      request_show: requestShowModule,
      admin: adminModule,
      rapid_review: rapidReviewModule
    },
    ...globalModule,
    plugins: [abilityPlugin]
  })
  const csrfEl = document.getElementsByName('csrf-token')
  const token = csrfEl.length > 0 ? csrfEl[0].content : ``
  axios.defaults.headers['X-CSRF-Token'] = token
  axios.defaults.headers.Accept = 'application/json'
  const el = document.querySelector('#content')
  app.component('Popper', Popper)

  app.use(router)
  app.use(store)
  app.use(abilitiesPlugin, ability, {
    useGlobalProperties: true
  })
  app.use(VueCookies)

  injectToasted(app)

  app.mixin(currentUserPresent)
  app.mixin(removeNullFromObject)

  app.mount(el)
})
