import requestsActions from '../pages/requests/store/actions'
import requestsMutations from '../pages/requests/store/mutations'
import { parseFilterParams } from '../pages/requests/store/parse-filter-params.ts'
import { filterState } from './filters-state'

const requestsModule = {
  namespaced: true,
  state: {
    allRequestsCount: 0,
    columns: [],
    currentPageNumber: 1,
    isLoading: false,
    requests: [],
    filters: filterState,
    filterOptions: {
      pocs: [],
      staff: [],
      requesters: [],
      departments: [],
      tags: [],
      closure_responses: []
    },
    filterFetchState: {
      departments: { page: 0, search_text: '', end: false, fetching: false },
      staff: { page: 0, search_text: '', end: false, fetching: false },
      pocs: { page: 0, search_text: '', end: false, fetching: false },
      requesters: { page: 0, search_text: '', end: false, fetching: false },
      tags: { page: 0, search_text: '', end: false, fetching: false },
      closure_responses: {
        page: 0,
        search_text: '',
        end: false,
        fetching: false
      }
    },
    selectedSavedView: null,
    savedViews: [],
    savedReports: [],
    searchResultsCount: 0,
    showFilter: true,
    tableHeaderTitle: '',
    tableHeaderTitleTooltip: '',
    resetSortIcons: false,
    heroOpen: true,
    reportPending: false,
    searchIntegrations: null
  }, // data
  actions: requestsActions, // methods to retrieve data and call mutations
  mutations: requestsMutations, // setting and updating state
  getters: {
    activeFilters: (state) =>
      Object.keys(parseFilterParams(state.filters)).filter((item) => {
        if (item === 'sort_field') {
          return false
        }
        if (item === 'sort_order') {
          return false
        }
        if (item === 'page_number') {
          return false
        }
        if (item === 'id') {
          return false
        }
        if (item === 'filter_name') {
          return false
        }
        return item
      })
  }
}

export { requestsModule }
