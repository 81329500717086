/* eslint-disable camelcase */
export interface DocumentMetrics {
  last_updated: string
  public: number
  redacted: number
  requester: number
  staff: number
  total: number
}
export interface DocumentPii {
  total_documents: number
  total_scanned: number
  high_severity: number
  medium_severity: number
  low_severity: number
  unscannable: number
}

export interface Tag {
  text: string
  color: string
  clickable: boolean
  icon: string
  icon_type: string
  value: string
}

export interface Tags extends Array<Tag> {}

export interface Document {
  id: number
  review_status: string
  severity: string
  title: string
  document_path: string
  pretty_id: string
  request_path: string
  disableClick?: boolean
  tags?: Tags
}

export interface UpdatedReviewState {
  id: number
  review_status: string
}

export interface Documents extends Array<Document> {}

export interface State {
  documentMetrics: DocumentMetrics
  documentPii: DocumentPii
  documents: Documents
}

export const state: State = {
  documentMetrics: {
    last_updated: '',
    public: 0,
    redacted: 0,
    requester: 0,
    staff: 0,
    total: 0
  },
  documentPii: {
    total_documents: 0,
    total_scanned: 0,
    high_severity: 0,
    medium_severity: 0,
    low_severity: 0,
    unscannable: 0
  },
  documents: []
}
