import { appToasted } from '../../../utils/vue-toasted/app_toasted.js'
import API from '../../../vue_shared/api' // eslint-disable-line import/no-useless-path-segments
import { globalModule } from '../../../store/globalModule'
import helpers from '../../../utils/helpers'
import uniqBy from 'lodash/uniqBy'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import { fetchRequestDataByType } from '../store/request_data'
import * as http from '../../../vue_shared/http'
import NrSentry from '../../../utils/nr_sentry'

function basicErrorHandle(error, message) {
  NrSentry.captureError(error)
  appToasted().error(message)
}

export default {
  reinitializeState({ commit }, val) {
    commit('reinitializeState', val)
  },

  setupRequestShowPageData: ({ dispatch }, data) => {
    return new Promise((resolve, reject) => {
      dispatch('fetchRequest', data.prettyId).catch(reject)
      const canFetchForInvoices =
        data.ability.can('read', 'Invoice') &&
        globalModule.state.account &&
        globalModule.state.account.payments

      if (canFetchForInvoices) {
        dispatch('fetchRequestInvoices', data.prettyId).catch(reject)
      }

      if (data.ability.can('read', 'staff_only_items')) {
        dispatch('fetchRequestStaff', {
          request_id: data.prettyId,
          all_results: true
        }).catch(reject)
        dispatch('fetchRequestTags', data.prettyId).catch(reject)
        dispatch('fetchRequestStaffHours', data.prettyId).catch(reject)
      }
      resolve()
    })
  },

  fetchRequest: ({ commit }, prettyId) => {
    API.fetchRequest(prettyId)
      .then((response) => {
        const request = response.data
        commit('setRequest', request)
        commit('setFilteredRequestLoading', false)
      })
      // eslint-disable-next-line no-unused-vars
      .catch((e) => {
        basicErrorHandle(e, 'There was an error fetching the request')
        window.location = '/requests'
      })
  },

  fetchRequestInvoices: ({ commit }, id) => {
    API.fetchRequestInvoices(id)
      .then((response) => {
        const invoices = response.data.invoices
        commit('setRequestInvoices', invoices)
      })
      // eslint-disable-next-line no-unused-vars
      .catch((e) => {
        basicErrorHandle(e, 'There was an error fetching request invoices')
      })
  },

  setTimelinePin: ({ state, dispatch }, params) => {
    const pinText = params.note_pinned ? 'pinned' : 'unpinned'
    API.setTimelinePin(params)
      .then(() => {
        dispatch('fetchTimeline', {
          pretty_id: state.request.pretty_id,
          filter_categories: []
        })
        appToasted().success(`Successfully ${pinText} note`)
      })
      .catch((e) => {
        basicErrorHandle(e, `There was a problem ${pinText} note`)
      })
  },

  updateNotePin: ({ state, dispatch }) => {
    const params = {
      request_id: state.request.pretty_id,
      request_event_id: state.selectedTimelineEvent.timeline_id,
      note_pinned: !state.selectedTimelineEvent.timeline_is_pinned
    }
    dispatch('setTimelinePin', params)
    dispatch('setCurrentAction', '')
  },

  handlePinChange: ({ state, dispatch, commit }, noteRequestEvent) => {
    commit('setSelectedTimelineEvent', noteRequestEvent)
    const noteAlreadyPinned = !isEmpty(state.pinnedNote)
    if (
      noteAlreadyPinned &&
      state.pinnedNote.timeline_id !== noteRequestEvent.timeline_id
    ) {
      dispatch('setCurrentAction', 'replacingPinnedNote')
      return
    }
    dispatch('updateNotePin')
  },

  fetchTimeline: ({ commit, state }, payload) => {
    API.fetchRequestTimeline(payload)
      .then((response) => {
        const timeline = helpers.attachTimelineIcons(response.data.timeline)
        const requestTimeline =
          payload.page_number > 1
            ? [...state.requestTimeline, ...timeline]
            : timeline
        commit('setTotalRequestTimelineCount', response.data.total_count)
        commit('setRequestTimeline', requestTimeline)
        const pinnedNote = response.data.pinned.length
          ? response.data.pinned[0]
          : {}
        commit('setPinnedNote', pinnedNote)
      })
      .catch((e) => {
        basicErrorHandle(e, 'An error has occured while retrieving timeline.')
      })
  },

  /* eslint-disable camelcase */
  fetchStaff: ({ commit, state }, search_text = '') => {
    const newFetchState = { ...state.dataFetchState.staff }
    if (
      state.dataFetchState.staff.search_text === search_text &&
      state.dataFetchState.staff.end
    ) {
      return
    }
    if (state.dataFetchState.staff.search_text === search_text) {
      Object.assign(newFetchState, {
        search_text,
        page: state.dataFetchState.staff.page + 1,
        fetching: true
      })
    }
    if (state.dataFetchState.staff.search_text !== search_text) {
      Object.assign(newFetchState, {
        search_text,
        page: 1,
        end: false,
        fetching: true
      })
    }
    const payload = {
      page_number: newFetchState.page,
      search_text
    }
    API.fetchStaff(payload)
      .then((response) => {
        const staff = response
        if (state.dataFetchState.staff.search_text !== search_text) {
          commit('setDataOptions', { staff: [] })
          commit('setDataOptions', { staff: staff })
        } else {
          commit('setDataOptions', {
            staff: uniqBy([...state.dataOptions.staff, ...staff], 'id')
          })
        }
        commit('setDataFetchState', {
          staff: Object.assign(newFetchState, {
            end: staff.length < 25,
            fetching: false
          })
        })
      })
      .catch((e) => {
        basicErrorHandle(e, 'An error occured. Please try again.')
        commit('setDataFetchState', {
          staff: Object.assign(newFetchState, {
            fetching: false
          })
        })
      })
  },

  /* eslint-disable camelcase */
  async fetchRequestStaff({ commit }, params) {
    const { request_id, ...options } = params
    return http
      .get(`/client/requests/${request_id}/staff`, options)
      .then((staff) => {
        commit('setRequestStaff', staff)
      })
  },

  /* eslint-disable camelcase */
  fetchRequestStaffHours: ({ commit }, prettyId) => {
    API.fetchRequestStaffHours({ pretty_id: prettyId })
      .then((response) => {
        const staff_hours = response.data.staff_hours
        commit('setRequestStaffHours', staff_hours)
      })
      // eslint-disable-next-line no-unused-vars
      .catch((e) => {
        basicErrorHandle(
          e,
          'An error has occured while retrieving staff hours.'
        )
      })
  },

  fetchRequestTags({ commit }, val) {
    API.fetchRequestTags(val)
      .then(({ data }) => {
        commit('setRequestTags', data)
      })
      // eslint-disable-next-line no-unused-vars
      .catch((e) => {
        basicErrorHandle(
          e,
          'An error has occurred while retrieving request tags.'
        )
      })
  },

  fetchRequestTasks({ commit }, val) {
    API.fetchRequestTasks(val)
      .then(
        ({
          data: {
            tasks,
            total_tasks_count: totalRequestTasksCount,
            current_tasks_count: currentRequestTasksCount
          }
        }) => {
          commit('setRequestTasks', tasks)
          commit('setCurrentRequestTasksCount', currentRequestTasksCount)
          commit('setTotalRequestTasksCount', totalRequestTasksCount)
        }
      )
      // eslint-disable-next-line no-unused-vars
      .catch((e) => {
        basicErrorHandle(
          e,
          'An error has occured while retrieving request tasks.'
        )
      })
  },

  fetchDocuments: ({ commit }, data) => {
    API.fetchRequestDocuments(data)
      .then((response) => {
        const {
          documents,
          total_documents_count: totalDocumentsCount,
          current_documents_count: currentDocumentsCount,
          documents_state_timestamp: documentsStateTimestamp
        } = response.data
        commit('setDocumentsStateTimestamp', documentsStateTimestamp)
        if (documents.length) {
          commit('setDocuments', documents)
          commit('setSearchDocumentResultsCount', totalDocumentsCount)
          commit('setCurrentDocumentsCount', currentDocumentsCount)
          commit('setTotalDocumentsCount', totalDocumentsCount)
        } else {
          commit('setDocuments', [])
          commit('setSearchDocumentResultsCount', 0)
          commit('setCurrentDocumentsCount', 0)
          commit('setTotalDocumentsCount', 0)
        }
      })
      .catch((e) => {
        basicErrorHandle(e, 'An error has occured while retrieving documents.')
      })
  },
  addDocument: ({ commit }, doc) => {
    commit('setDocument', doc)
  },
  addFolder: ({ commit, state }, folder) => {
    commit('setDocumentsFolders', [
      ...state.documentsFolders,
      { ...folder, has_child_folders: folder.has_child_folders || false }
    ])
  },
  fetchDocumentsFolders: ({ commit, state }, data) => {
    API.fetchRequestDocumentsFolders(data)
      .then((response) => {
        const { folders: responseFolders } = response.data
        const folders =
          data.page_number > 1
            ? [...state.documentsFolders, ...responseFolders]
            : responseFolders
        commit('setTotalDocumentsFoldersCount', response.data.total_count)
        commit('setDocumentsFolders', folders)
      })
      .catch((e) => {
        basicErrorHandle(
          e,
          'An error has occured while retrieving documents folders.'
        )
      })
  },

  fetchDocumentsChildFolders: ({ commit, state }, data) => {
    const { documentsFolders } = state
    API.fetchRequestDocumentsChildFolders(data)
      .then((response) => {
        const { folders } = response.data
        // eslint-disable-next-line no-unused-vars
        const newFolders = documentsFolders.map((folder) => {
          if (folder.id === data.folder_id) {
            const existingChildFolders = folder.folders || []
            const newChildFolders = folders
            if (!newChildFolders.length) {
              appToasted().show('No more subfolders found within this folder.')
            }
            const newFolder = {
              ...folder,
              folders: uniqBy(
                [...existingChildFolders, ...newChildFolders],
                'id'
              )
            }
            return newFolder
          }
          return folder
        })
        commit('setDocumentsChildFolders', newFolders)
      })
      .catch((e) => {
        basicErrorHandle(
          e,
          'An error has occured while retrieving document sub folders.'
        )
      })
  },

  // eslint-disable-next-line no-empty-pattern
  submitBulkDocumentAction: ({}, payload) => {
    return API.bulkDocumentAction(payload)
  },

  // eslint-disable-next-line no-empty-pattern
  fetchDocFiles: ({}, payload) => {
    return API.fetchDocFiles(payload)
  },

  // eslint-disable-next-line no-empty-pattern
  fetchJobStatuses: ({}, payload) => {
    return API.jobStatuses(payload)
  },

  downloadDocuments: ({ dispatch, state }, payload) => {
    dispatch('fetchDocFiles', {
      document_id: payload.docId,
      jid: payload.jid,
      request_id: state.request.pretty_id
    })
      .then((response) => {
        const link = document.createElement('a')
        link.href = response.data.url
        link.setAttribute('download', response.data.filename)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        if (response.data.bulk) {
          helpers.removePendingJobFromLocalStorage(
            `${globalModule.state.currentUser.id}${state.request.pretty_id}requestShowDownload`,
            payload.jid
          )
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((e) => {
        basicErrorHandle(e, 'There was a problem downloading file(s)')
        helpers.removePendingJobFromLocalStorage(
          `${globalModule.state.currentUser.id}${state.request.pretty_id}requestShowDownload`,
          payload.jid
        )
      })
  },

  setDocumentFilters: ({ commit }, data) => {
    commit('setDocumentFilters', data)
  },

  // eslint-disable-next-line no-empty-pattern
  submitExternalMessage: ({ dispatch }, payload) => {
    API.submitExternalMessage(payload)
      .then(() => {
        appToasted().success(`Your message was sent.`)
        setTimeout(() => {
          dispatch('fetchTimeline', {
            pretty_id: payload.request_id,
            filter_categories: []
          })
        }, 1000)
      })
      .catch((e) => {
        basicErrorHandle(e, 'There was an error sending your message.')
      })
  },

  submitRequestStaffTime: ({ commit, dispatch, state }, payload) => {
    API.submitRequestStaffTime(payload)
      .then((response) => {
        appToasted().success(`Staff hour was submitted.`)
        const request = state.request
        request.request_staff_hours = response.data.total_staff_hours
        request.request_staff_cost = response.data.staff_cost
        commit('setRequest', request)
        commit('setRequestStaffHours', response.data.staff_hours)
        dispatch('fetchTimeline', {
          pretty_id: payload.request_id,
          filter_categories: []
        })
      })
      .catch((e) => {
        basicErrorHandle(e, 'There was an error submitting your staff hour.')
      })
  },

  submitInternalMessage: ({ dispatch }, payload) => {
    API.submitInternalMessage(payload)
      .then(() => {
        appToasted().success(`Your message was sent.`)
        setTimeout(() => {
          dispatch('fetchTimeline', {
            pretty_id: payload.request_id,
            filter_categories: []
          })
        }, 1000)
      })
      .catch((e) => {
        basicErrorHandle(e, 'There was an error sending your message.')
      })
  },

  submitNewTask: ({ dispatch, state }, payload) => {
    API.submitNewTask(payload)
      .then(() => {
        appToasted().success('Successfully created a new task.')
        dispatch('setDisplayCreateTaskMsgBox', { display: false })
        dispatch('fetchTimeline', {
          pretty_id: state.request.pretty_id,
          filter_categories: []
        })
        dispatch('fetchRequestTasks', { pretty_id: state.request.pretty_id })
        dispatch('fetchRequest', state.request.pretty_id)
      })
      .catch((e) => {
        basicErrorHandle(e, 'There was an error creating the new task.')
      })
  },

  submitStaffChange: ({ commit, dispatch, state }, payload) => {
    API.submitStaffChange(payload)
      .then((response) => {
        let newStaff = response.data
        appToasted().success(`Staff assigned ${payload.action}d`)
        if (payload.action === 'remove') {
          if (!payload.id) return
          newStaff = state.requestStaff.filter((user) => {
            return user.id !== payload.id
          })
        }
        commit('setRequestStaff', newStaff)
        dispatch('fetchTimeline', {
          pretty_id: state.request.pretty_id,
          filter_categories: []
        })
      })
      .catch((e) => {
        basicErrorHandle(
          e,
          'There was an error updating assigned staff on the request. Please try again.'
        )
      })
  },

  editTaskCompletion: ({ dispatch, commit, state }, payload) => {
    API.submitNewTask(payload)
      .then((response) => {
        appToasted().success('Successfully updated the task.')
        const task = state.requestTasks.find(
          (item) => +item.id === +response.data.task_id
        )
        task.completed = response.data.completed
        task.status = response.data.task_status
        task.due_date = response.data.display_date
        task.completed_date = response.data.completed_date
        commit('setTask', task)
        dispatch('fetchTimeline', {
          pretty_id: payload.request_id,
          filter_categories: []
        })
      })
      .catch((e) => {
        basicErrorHandle(e, 'There was an error updating the task.')
      })
  },

  fetchInvoiceTemplates: ({ commit }, payload = {}) => {
    API.invoiceTemplates(payload).then((response) => {
      const invoiceTemplates = response.data.invoice_templates
      if (invoiceTemplates) {
        commit('setInvoiceTemplates', invoiceTemplates)
      }
    })
  },

  // eslint-disable-next-line no-empty-pattern
  sendNotification: ({ dispatch, state }, payload) => {
    API.sendNotification(payload).then((response) => {
      if (response.status === 204) {
        setTimeout(() => {
          dispatch('fetchTimeline', {
            pretty_id: state.request.pretty_id,
            filter_categories: []
          })
        }, 3000)
      }
    })
  },

  setSelectedChangeDocVisibility: ({ commit }, val) => {
    commit('setSelectedChangeDocVisibility', val)
  },

  switchToOldPage: ({ commit }) => {
    API.previewSwitch(false).then((data) => {
      if (data.data.status === 'ok') {
        commit('setPreviewRequestPage', false)
      }
    })
  },

  setMessageToRequester: ({ commit }, val) => {
    commit('setMessageToRequester', val)
  },

  // eslint-disable-next-line no-empty-pattern
  moveFolder: ({}, { payload, deleteMethod }) => {
    return API.updateFolder(payload, deleteMethod)
  },

  updateDocTitle: ({ dispatch, state }, payload) => {
    API.updateDocTitle(payload)
      .then(() => {
        const { id, title } = payload
        appToasted().success('Successfully renamed document title.')
        const document = state.documents.filter((doc) => {
          return doc.id === id
        })
        if (document.length) {
          const doc = document[0]
          doc.title = title
          dispatch('setDocument', doc)
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((e) => {
        basicErrorHandle(e, 'An error has occured while renaming document.')
      })
  },

  updateMessage: ({ dispatch, state }, payload) => {
    API.updateMessage(payload).then(() => {
      dispatch('fetchTimeline', {
        pretty_id: state.request.pretty_id,
        filter_categories: []
      })
    })
  },

  setDocument: ({ commit }, val) => {
    commit('setDocument', val)
  },

  setActiveModal: ({ commit }, val) => {
    commit('setActiveModal', val)
  },

  setDisplayInternalMsgBox({ commit }, val) {
    commit('setDisplayInternalMsgBox', val)
  },

  setDisplayExternalMsgBox({ commit }, val) {
    commit('setDisplayExternalMsgBox', val)
  },

  setDisplayMsgBox({ commit }, val) {
    commit('setDisplayMsgBox', val)
  },

  setDisplayCreateInvoiceMsgBox({ commit }, val) {
    commit('setDisplayCreateInvoiceMsgBox', val)
  },

  setDisplayCreateStaffTimeBox({ commit }, val) {
    commit('setDisplayCreateStaffTimeBox', val)
  },

  setDisplayCreateTaskMsgBox({ commit, state }, val) {
    commit(
      'setDisplayCreateTaskMsgBox',
      Object.assign({}, state.displayCreateTaskMsgBox, val)
    )
  },

  setDisplayEditRequestTextBox({ commit }, val) {
    commit('setDisplayEditRequestTextBox', val)
  },

  setCurrentAction({ commit }, val) {
    commit('setCurrentAction', val)
  },

  setDocumentsProcessingIds({ commit }, val) {
    commit('setDocumentsProcessingIds', val)
  },

  setEditingTask({ commit }, val) {
    commit('setEditingTask', val)
  },

  processInvoice({ dispatch, commit }, payload) {
    API.processInvoice(payload)
      .then((response) => {
        dispatch('fetchTimeline', {
          pretty_id: payload.request_id,
          filter_categories: []
        })
        commit('setRequestInvoice', response.data)
        appToasted().success(
          `Your invoice was successfully ${payload.action}d.`
        )
      })
      .catch((e) => {
        const action = payload.action.slice(0, -1)
        basicErrorHandle(e, `There was an error ${action}ing your invoice.`)
      })
  },

  processStaffHour({ dispatch, commit, state }, payload) {
    API.processStaffHour(payload)
      .then((response) => {
        dispatch('fetchTimeline', {
          pretty_id: payload.request_id,
          filter_categories: []
        })
        const staff_hours = response.data.staff_hours
        const request = state.request
        request.request_staff_hours = response.data.total_staff_hours
        request.request_staff_cost = response.data.staff_cost
        commit('setRequest', request)
        commit('setRequestStaffHours', staff_hours)
        appToasted().success(`Staff hours was successfully ${payload.action}d.`)
      })
      .catch((e) => {
        const action = payload.action.slice(0, -1)
        basicErrorHandle(e, `There was an error ${action}ing staff hours.`)
      })
  },

  // eslint-disable-next-line no-empty-pattern
  deleteTask({}, val) {
    return API.deleteTask(val)
  },

  // eslint-disable-next-line no-empty-pattern
  updateFolder: ({}, data) => {
    return API.updateFolder(data)
  },

  fetchRequestDocumentRisk: ({ commit }, data) => {
    API.fetchRequestDocumentRisk(data)
      .then((response) => {
        commit('setRequestDocumentRisk', response.data)
      })
      .catch((e) => {
        basicErrorHandle(e, 'There was an error fetching document risk data')
      })
  },

  async updateRequest({ commit }, data) {
    return API.updateRequest(data).then((response) =>
      commit('setRequest', response.data)
    )
  },

  async reopen({ commit, dispatch }, prettyId) {
    return http
      .put(`/client/requests/${prettyId}`, {
        state: 'Open'
      })
      .then((res) => {
        dispatch('fetchTimeline', { pretty_id: prettyId })
        commit('setRequest', res)
      })
      .catch((e) => {
        basicErrorHandle(
          e,
          'There was an error reopening the request. Please reload the page and try again.'
        )
      })
  },

  async pause({ commit, dispatch }, { prettyId, description }) {
    return http
      .put(`/client/requests/${prettyId}`, {
        state: 'Waiting for requester',
        description
      })
      .then((res) => {
        dispatch('fetchTimeline', { pretty_id: prettyId })
        commit('setRequest', res)
      })
      .catch((e) => {
        basicErrorHandle(
          e,
          'There was an error pausing the request. Please reload the page and try again.'
        )
      })
  },

  async resume({ commit, dispatch }, prettyId) {
    return http
      .put(`/client/requests/${prettyId}`, { state: 'Unpause' })
      .then((res) => {
        dispatch('fetchTimeline', { pretty_id: prettyId })
        commit('setRequest', res)
      })
      .catch((e) => {
        basicErrorHandle(
          e,
          'There was an error resuming the request. Please reload the page and try again.'
        )
      })
  },

  async updateDuedate({ commit }, { prettyId, date, description }) {
    return http
      .put(`/client/requests/${prettyId}`, { due_date: date, description })
      .then((res) => commit('setRequest', res))
      .catch((error) => {
        NrSentry.captureError(error)
        throw error
      })
  },

  async updateRequester({ commit, dispatch }, { prettyId, requesterId }) {
    return http
      .put(`/client/requests/${prettyId}`, {
        requester_ids: [requesterId]
      })
      .then((res) => {
        dispatch('fetchTimeline', { pretty_id: prettyId })
        commit('setRequest', res)
      })
      .catch((error) => {
        basicErrorHandle(
          error,
          'There was an error changing the requester. Please reload the page and try again.'
        )
      })
  },

  async updateCustomField({ commit, state }, { prettyId, field }) {
    if (!prettyId || (!field && !field.slug) || !state.request.custom_fields) {
      return
    }

    const customFields = state.request.custom_fields.map((customField) => {
      return {
        ...customField,
        value: customField.slug === field.slug ? field.value : customField.value
      }
    })

    return http
      .put(`/client/requests/${prettyId}`, { custom_field_data: customFields })
      .then((res) => {
        commit('setRequest', res)
      })
      .catch((error) => {
        basicErrorHandle(
          error,
          'There was an error updating additional information. Please reload the page and try again.'
        )
      })
  },

  async closeRequest({ commit }, { prettyId, description, reasons, state }) {
    return http
      .put(`/client/requests/${prettyId}`, {
        description,
        close_request_type_ids: reasons,
        state: state || 'Closed'
      })
      .then(() => {
        appToasted().success('Successfully closed the request')
        commit('setStatus', 'Closed')
      })
      .catch((e) => {
        basicErrorHandle(
          e,
          'There was an error closing the request. Please reload the page and try again.'
        )
      })
  },
  async fetchClosureTemplates({ commit, state }, params) {
    // eslint-disable-next-line camelcase
    const search_text = params && params.search_text ? params.search_text : ''
    const page_number = params && params.page_number ? params.page_number : 1
    return (
      http
        // eslint-disable-next-line camelcase
        .get('/client/close_request_types', { search_text, page_number })
        .then((response) => {
          if (page_number) {
            commit(
              'setClosureTemplates',
              state.closureTemplates.concat(response)
            )
          } else {
            commit('setClosureTemplates', response)
          }

          return response.length >= 25
        })
        .catch((e) => {
          basicErrorHandle(
            e,
            'There was a problem fetching closure response templates'
          )
        })
    )
  },

  fetchDepartmentPocs(_, { departmentIds }) {
    return http.get('/department_pocs', {
      ids: departmentIds
    })
  },

  async updateReceivedDate(
    { commit, dispatch },
    { prettyId, date, description }
  ) {
    return http
      .put(`/client/requests/${prettyId}`, {
        request_date: date,
        description
      })
      .then((res) => {
        dispatch('fetchTimeline', { pretty_id: prettyId })
        commit('setRequest', res)
      })
  },
  fetchDataByType({ commit, state }, { dataType, newFetchState, payload }) {
    const requestDataTypes = Object.keys(state.dataOptions)
    if (includes(requestDataTypes, dataType)) {
      fetchRequestDataByType(payload)
        .then((res) => {
          if (
            state.dataFetchState[dataType].search_text !== payload.search_text
          ) {
            commit('setDataOptions', { [dataType]: [] })
            commit('setDataOptions', { [dataType]: res })
          } else {
            commit('setDataOptions', {
              [dataType]: uniqBy([...state.dataOptions[dataType], ...res], 'id')
            })
          }
          commit('setDataFetchState', {
            [dataType]: Object.assign(newFetchState, {
              end: res.length < 25,
              fetching: false
            })
          })
        })
        .catch(() => {
          commit('setDataFetchState', {
            [dataType]: Object.assign(newFetchState, {
              fetching: false
            })
          })
        })
    }
  },
  fetchDataOptions: ({ dispatch, state }, { searchText = '', dataType }) => {
    const newFetchState = { ...state.dataFetchState[dataType] }
    if (
      state.dataFetchState[dataType].search_text === searchText && // eslint-disable-line camelcase
      state.dataFetchState[dataType].end
    ) {
      return
    }
    if (state.dataFetchState[dataType].search_text === searchText) {
      Object.assign(newFetchState, {
        search_text: searchText,
        page: state.dataFetchState[dataType].page + 1,
        fetching: true
      })
    }
    // eslint-disable-next-line camelcase
    if (state.dataFetchState[dataType].search_text !== searchText) {
      Object.assign(newFetchState, {
        search_text: searchText,
        page: 1,
        end: false,
        fetching: true
      })
    }
    const payload = {
      search_text: searchText,
      page_number: newFetchState.page,
      type: dataType
    }
    if (dataType === 'requesters') {
      Object.assign(payload, { all_available_requesters: true })
    }
    dispatch('fetchDataByType', { dataType, newFetchState, payload })
  }
}
